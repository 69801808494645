<template>
  <div>
    <div>
      <NavBar :bool="true" @navCallback="navCallback" class="nav-bar"></NavBar>
      <div class="success-info">
        <div class="success-prompt">
          <img src="~@/assets/order/ok@2x.png" class="success-img" />
          <span class="pay-ok">支付成功</span>
          <span class="actual-money" v-if="orderType !== 3"
            >实付金额￥{{ twoNum(payMoney) }}</span
          >
          <span class="actual-money" v-else
            >实付{{ toInteger(payMoney) || 0 }}公益豆</span
          >
        </div>
      </div>
      <div class="operation">
        <div class="operation-text" @click="goToHome">返回首页</div>
        <div class="operation-text text-red" @click="goToOrderInfo">
          查看订单
        </div>
      </div>
    </div>
    <div class="recommend">
      <div class="title">-为你推荐-</div>
      <div>
        <GoodsShowLabel :tagId="0" :sendType="3"></GoodsShowLabel>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar";
import GoodsShowLabel from "@/components/goods-show/goods-show-label";
export default {
  name: "pay-success",
  components: {
    GoodsShowLabel,
    NavBar,
  },
  data() {
    return {
      colorOrFont: {
        textSize: "22px",
        fontBold: "600",
      },
      isFixed: true,
      list: [],
      error: false,
      loading: false,
      orderId:
        this.$route.query.orderId === undefined
          ? "0"
          : this.$route.query.orderId,
      orderType:
        this.$route.query.orderType === undefined
          ? "1"
          : this.$route.query.orderType,
      payMoney:
        this.$route.query.payMoney === undefined
          ? "0"
          : this.$route.query.payMoney,
    };
  },
  mounted() {
    // console.log("pay-success------------", this.$route.query.orderType);
  },
  methods: {
    twoNum(num) {
      // 获取两位小数
      return (num * 1).toFixed(2);
    },
    goToOrderInfo() {
      //跳转订单详情
      // console.log("orderType-------------", this.orderType);
      if (this.orderType * 1 === 2) {
        this.$router.push({
          name: "purchases-order",
          query: { orderType: 2, orderId: this.orderId },
        });
      } else if (this.orderType * 1 === 3) {
        this.$router.push({
          name: "all-order",
          query: { orderType: 3, orderId: this.orderId },
        });
      } else {
        this.$router.push({
          name: "all-order",
          query: { orderType: 1, orderId: this.orderId },
        });
      }
    },
    goToHome() {
      this.$router.push({ name: "home" });
    },
    navCallback() {
      this.$router.push({
        path: "/goods",
        query: {
          goodsId: localStorage.getItem("GOODS_ID"),
        },
      });
      localStorage.removeItem("GOODS_ID");
    },
    toInteger(num) {
      // 取整
      let toNum = "";
      toNum = parseInt(num);
      return toNum;
    },
  },
};
</script>

<style lang="less" scoped>
.nav-bar {
  background-color: #fff;
}
.success-info {
  background-color: #fff;
  padding: 70px 10px 10px 10px;
  border-bottom: 1px dashed #c6c6c6;
}
.success-prompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  .success-img {
    width: 52px;
    box-shadow: 0px 2px 4px rgba(252, 0, 0, 0.16);
    border-radius: 50%;
  }
  .pay-ok {
    font-size: 20px;
    font-weight: 500;
    margin: 10px 0;
  }
  .actual-money {
    font-size: 20px;
    color: #6f6f6f;
  }
}
.operation {
  display: flex;
  justify-content: space-around;
  padding: 20px 80px;
  background-color: #fff;
  .operation-text {
    width: 102px;
    height: 39px;
    border: 1px solid #6f6f6f;
    opacity: 1;
    border-radius: 19px;
    font-size: 16px;
    line-height: 39px;
    text-align: center;
    &:active {
      background-color: rgb(235, 235, 235);
    }
  }
  .text-red {
    color: #ed301d;
    border: 1px solid #ed301d;
  }
}
.recommend {
  padding-top: 12px;
  .title {
    width: 100%;
    font-size: 18px;
    text-align: center;
    font-weight: 500;
  }
}
</style>
